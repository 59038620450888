<template>
  <div class="w-100 mt-25">
    <FormView
      :data="data"
      :fields="fields"
      lazy
    />
  </div>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

export default {
  components: {
    FormView,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fields() {
      const fields = [
        {
          key: 'isEnabled',
          label: 'Enabled',
        },
        {
          key: 'pricePerSecond',
          label: 'Price Per Second',
          prefix: '$',
        },
        {
          key: 'maxDuration',
          label: 'Max Duration',
          suffix: ' second(s)',
        },
        {
          key: 'manualApprove',
          label: 'Manual Approval',
        },
        {
          key: 'spamSecurityLevel',
          label: 'Spam Security',
        },
        {
          key: 'mediaProgressBar',
          label: 'Media Progress Bar',
        },
        {
          key: 'progressBarColor',
          label: 'Progress Bar Color',
          type: 'color',
        },
        {
          key: 'progressBarTextColor',
          label: 'Progress Bar Text Color',
          type: 'color',
        },
        {
          key: 'volume',
          label: 'Volume',
        },
        {
          key: 'showVideo',
          label: 'Show Video',
        },
      ]

      return fields.filter(item => item.visible !== false)
    },
  },
}
</script>

<style scoped>

</style>
