<template>
  <div class="w-100 mt-25">
    <FormView
      :data="data"
      :fields="fields"
      lazy
    />
  </div>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

export default {
  components: {
    FormView,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fields() {
      const fields = [
        {
          key: 'filterMode',
          label: 'Filter mode',
          type: 'dot',
          map: {},
        },
        {
          key: 'enableCustomBadWord',
          label: 'Enable custom list',
        },
        {
          key: 'customBadWordList',
          label: 'Custom list',
          type: 'badges',
          map: {},
        },
        {
          key: 'enableStandardBadWord',
          label: 'Enable standard list',
        },
      ]

      return fields.filter(item => item.visible !== false)
    },
  },
}
</script>

<style scoped>

</style>
