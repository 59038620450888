<!-- eslint-disable vue/no-v-html -->
<template>
  <b-overlay
    variant="white"
    :show="loadingDetail || loadingSubmit"
  >
    <div v-if="false">
      <div
        v-for="(value, key) in defaultFields"
        :key="key"
        class="mb-1"
      >
        <div
          v-for="(value2, key2) in value"
          :key="key2"
        >
          <b-row>
            <b-col cols="2" class="text-right">
              {{ key2 }} :
            </b-col>
            <b-col :class="{ 'font-weight-bold text-primary': key2 === 'key' }">
              {{ value2 }}
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <!-- Form -->
    <validation-observer ref="formValidation">
      <b-form
        ref="form"
        class="mt-50"
        @submit.prevent=""
      >
        <div
          v-for="(item, index) in defaultFields"
          :key="`field-${item.key}-${index}`"
        >
          <!-- Section -->
          <div
            v-if="item.type === 'section' && (!item.visibility || item.visibility.callback(defaultFields))"
            class="mb-3"
          >
            <hr class="mb-2">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>

          <!-- Fields -->
          <b-row
            v-else-if="!item.visibility || item.visibility.callback(defaultFields)"
            class="mb-2"
          >
            <!-- Label and note -->
            <b-col
              md="4"
              :xl="block ? 4 : 3"
            >
              <b-form-row class="align-items-center">
                <!-- Label -->
                <b-col cols="auto">
                  <h5 class="mb-0 font-weight-bolder">
                    {{ item.label }}
                  </h5>
                </b-col>
                <!-- Required badge -->
                <b-col>
                  <b-badge
                    v-if="item.required"
                    variant="light-secondary"
                  >
                    Required
                  </b-badge>
                </b-col>
              </b-form-row>
              <!-- Notes -->
              <div
                v-if="item.notes && Array.isArray(item.notes) && item.notes.length"
                class="mt-75"
              >
                <p
                  v-for="(note, noteIndex) in item.notes"
                  :key="`field-${item.key}-${index}-note-${noteIndex}`"
                  class="s-12 mb-50"
                  v-html="note"
                />
              </div>
              <div
                v-else-if="item.notes"
                class="mt-75"
              >
                <p
                  class="s-12 mb-50"
                  v-html="item.notes"
                />
              </div>
            </b-col>

            <!-- Fields -->
            <b-col
              md="8"
              :xl="block ? 8 : 6"
            >
              <validation-provider
                #default="{ errors }"
                :name="item.label"
                :rules="getRules(item)"
              >
                <!-- Email -->
                <b-form-input
                  v-if="item.type === 'email'"
                  v-model="item.value"
                  type="email"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <!-- Number -->
                <b-form-input
                  v-else-if="item.type === 'number'"
                  v-model="item.value"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <!-- Password -->
                <b-form-input
                  v-else-if="item.type === 'password'"
                  v-model="item.value"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <!-- Textarea -->
                <b-form-textarea
                  v-else-if="item.type === 'textarea'"
                  v-model="item.value"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <!-- Switch -->
                <b-form-checkbox
                  v-else-if="item.type === 'switch'"
                  v-model="item.value"
                  switch
                  inline
                  @input="input"
                >
                  {{ item.text }}
                </b-form-checkbox>
                <!-- Single and multiple select -->
                <div v-else-if="item.type === 'select' || item.type === 'multiple'">
                  <!-- For 'required' rule validation workaround -->
                  <!-- Because the item.selected is always null for 'multiple' type -->
                  <input
                    v-if="item.type === 'multiple'"
                    :ref="`select-input-${item.key}`"
                    :value="item.value && item.value.length ? true : undefined"
                    type="text"
                    class="vs__multiple_input"
                  >
                  <v-select
                    v-if="item.actionSearch || item.options"
                    v-model="item.selected"
                    :placeholder="item.type === 'multiple' ? 'Select options' : 'Select an option'"
                    :label="item.text || 'text'"
                    :filterable="!item.actionSearch"
                    :options="item.stateOptions ? _.get($store.state, item.stateOptions) : item.options"
                    @option:selected="option => inputSelect(item, option)"
                    @input="option => inputDeselect(item, option)"
                    @close="onBlurSelect(item)"
                    @search="(keyword, setLoading) => searchOptions(item, keyword, setLoading)"
                  >
                    <!-- No options -->
                    <template #no-options="{ search }">
                      <span v-if="search">There is no result for "{{ search }}".</span>
                      <span v-else-if="!item.actionSearch">No options</span>
                      <span v-else>Type to search...</span>
                    </template>
                  </v-select>
                  <b-input-group
                    v-else
                    class="input-group-merge"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false:null"
                      @keydown.enter.prevent="e => inputBasicMultiple(item, e)"
                    />
                    <b-input-group-append
                      v-if="!item.actionSearch && !item.options"
                      is-text
                    >
                      <small class="mr-25">Enter</small><feather-icon icon="CornerDownLeftIcon" />
                    </b-input-group-append>
                  </b-input-group>

                  <div
                    v-if="item.type === 'multiple' && item.value && item.value.length"
                    class="d-flex gap-1 flexwrap-wrap mt-50"
                  >
                    <b-badge
                      v-for="(selected, selectedIndex) in item.valueOptions"
                      :key="`field-${item.key}-${index}-selected-${selectedIndex}`"
                      class="whitespace-nowrap"
                      variant="primary"
                    >
                      {{ selected[item.text || 'text'] }}
                      <feather-icon
                        class="cursor-pointer"
                        icon="XIcon"
                        size="12"
                        @click="removeOption(item, selectedIndex)"
                      />
                    </b-badge>
                  </div>
                </div>
                <!-- Radio -->
                <b-form-radio-group
                  v-else-if="item.type === 'radio'"
                  v-model="item.value"
                  :options="item.options"
                  @change="input"
                />
                <!-- Slider -->
                <vue-slider
                  v-else-if="item.type === 'slider'"
                  v-model="item.value"
                  class="mb-2"
                  :min="item.min"
                  :max="item.max"
                  :marks="item.marks"
                  :interval="item.interval"
                  @change="input"
                />
                <!-- Datetime -->
                <b-input-group
                  v-else-if="item.type === 'datetime'"
                  class="input-group-merge position-relative"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-prepend>
                  <flat-pickr
                    v-model="item.value"
                    placeholder="Select date"
                    class="form-control"
                    :config="flatPickrConfig"
                    @input="input"
                  />
                </b-input-group>

                <!-- Quill text editor -->
                <quill-editor
                  v-else-if="item.type === 'quill'"
                  v-model="item.value"
                  :options="quillOptions"
                  @input="input"
                />

                <!-- Tinymce text editor -->
                <editor
                  v-else-if="item.type === 'tinymce'"
                  v-model="item.value"
                  api-key="52exxhds5yalt387oqn91m4r88j6aux4y5d51wi8fyufr2hu"
                  :init="config"
                  @input="input"
                />
                <!-- Upload image -->
                <image-field
                  v-else-if="item.type === 'image'"
                  v-model="item.value"
                  @input="input"
                />

                <!-- Upload Multiple Image -->
                <multiple-image-field
                  v-else-if="item.type === 'multiple-image'"
                  v-model="item.value"
                  @input="input"
                />

                <!-- Other -->
                <b-form-input
                  v-else
                  v-model="item.value"
                  :disabled="item.disabled"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <!-- Submit button -->
        <div class="border-top pt-2">
          <b-form-row class="justify-content-end">
            <!-- Reset -->
            <b-col
              v-if="false"
              cols="auto"
            >
              <b-button
                variant="flat-dark"
                @click="reset"
              >
                Reset
              </b-button>
            </b-col>
            <!-- Cancel -->
            <b-col cols="auto">
              <b-button
                variant="flat-dark"
                @click="cancel"
              >
                Cancel
              </b-button>
            </b-col>
            <!-- Save and stay still, do not show on default form -->
            <b-col
              v-if="(create || update) && !returnOnly"
              cols="auto"
            >
              <b-button
                v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                :disabled="loadingSubmit || !isSubmitAllowed"
                type="submit"
                variant="primary"
                class="d-flex align-items-center"
                @click="submit('stay')"
              >
                <b-spinner
                  v-if="loadingSubmit && submitType === 'stay'"
                  class="mr-50"
                  small
                />
                <span v-if="update">Update & Continue Editing</span>
                <span v-else>Create & Add Another</span>
              </b-button>
            </b-col>
            <!-- Save and return, do not show on default form -->
            <b-col
              v-if="create || update"
              cols="auto"
            >
              <b-button
                v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                :disabled="loadingSubmit || !isSubmitAllowed"
                type="submit"
                variant="primary"
                class="d-flex align-items-center"
                @click="submit('return')"
              >
                <b-spinner
                  v-if="loadingSubmit && submitType === 'return'"
                  class="mr-50"
                  small
                />
                <span v-if="update">Update {{ label }}</span>
                <span v-else>Create {{ label }}</span>
              </b-button>
            </b-col>
            <!-- Default submit -->
            <b-col
              v-if="!create && !update"
              cols="auto"
            >
              <b-button
                :disabled="loadingSubmit || !isSubmitAllowed"
                type="submit"
                variant="primary"
                class="d-flex align-items-center"
                @click="submit('stay')"
              >
                <b-spinner
                  v-if="loadingSubmit"
                  class="mr-50"
                  small
                />
                <span v-if="submitLabel">{{ submitLabel }}</span>
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-form-row>
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import '@/assets/scss/components/quill.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, max } from '@validations'
import { updatedDiff } from 'deep-object-diff'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ImageField from '@/layouts/components/ImageField.vue'
import MultipleImageField from '@/layouts/components/MultipleImageField.vue'
import flatPickr from 'vue-flatpickr-component'
import VueSlider from 'vue-slider-component'
import { quillEditor } from 'vue-quill-editor'
import Editor from '@tinymce/tinymce-vue'
import get from 'lodash/get'

import {
  BRow,
  BFormRow,
  BCol,
  BForm,
  BFormInput,
  BFormTextarea,
  BButton,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import debounce from 'lodash/debounce'

export default {
  components: {
    BFormRow,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
    BFormCheckbox,
    VueSlider,
    BSpinner,
    BOverlay,
    ImageField,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    quillEditor,
    flatPickr,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    MultipleImageField,
    vSelect,
    Editor,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    create: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
    loadingDetail: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      default: '',
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: '',
    },
    returnOnly: {
      type: Boolean,
      default: false,
    },
    // do not load data on initial state(mounted)
    lazy: {
      type: Boolean,
      default: false,
    },
    // submit the one that changed only
    diffOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      submitType: '',
      isSubmitAllowed: true,

      defaultFields: this._.cloneDeep(this.fields),

      quillOptions: {
        modules: {
          // doc: https://quilljs.com/docs/modules/toolbar/
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['blockquote', 'code-block'],
            [{ indent: '-1' }, { indent: '+1' }],
          ],
        },
      },

      config: {
        content_style:
          'body { background: #04101B; color: white; font-size: 14pt; font-family: Avenir; }'
          + 'h1 { margin: 0; color: white} h2 { margin: 0; color: white} h3 { margin: 0; color: white} h4 { margin: 0; color: white} h5 { margin: 0; color: white} h6 { margin: 0; color: white} p { margin: 0; color: white}',
        height: 500,
        style_formats: [
          {
            title: 'Headings',
            items: [
              { title: 'Heading 1', block: 'h1', styles: { color: '#ffffff' } },
              { title: 'Heading 2', block: 'h2', styles: { color: '#ffffff' } },
              { title: 'Heading 3', block: 'h3', styles: { color: '#ffffff' } },
              { title: 'Heading 4', block: 'h4', styles: { color: '#ffffff' } },
              { title: 'Heading 5', block: 'h5', styles: { color: '#ffffff' } },
              { title: 'Heading 6', block: 'h6', styles: { color: '#ffffff' } },
              { title: 'Paragraph', block: 'p', styles: { color: '#ffffff' } },
            ],
          },
        ],
        images_upload_url: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
        images_upload_handler: (blobInfo, success, failure) => {
          this.handleUpload(blobInfo, success, failure)
        },
        branding: false,
      },

      flatPickrConfig: {
        altInput: true,
        altFormat: 'j F Y, H:i',
        enableTime: true,
        dateFormat: 'Z',
      },

      searchOptionsLimit: 5,

      // validation rules
      required,
      email,
      max,
    }
  },
  watch: {
    // this listener won't be triggered if the component is not visible(mounted)
    // when the data changes. For instance if the component within a modal
    // and the modal is not visible on the first place
    data() {
      this.bindFormData()
    },
  },
  created() {
    if (this.update) {
      // reset previous form data (if any)
      this.reset()
    }
  },
  mounted() {
    // preload select options from API
    this.loadFieldOptions()

    if (!this.update) {
      return
    }

    // Only get data from API on update state and not lazy mode
    if (!this.lazy) {
      this.loadData(this.$route.params.id)
    } else if (this.data) {
    // Bind data to form if the data is already exist
    // Must be because of the component is visible when the data loaded already
    // For instance if the component within a modal
      this.bindFormData()
    }

    // should edit first to enable submit button
    this.isSubmitAllowed = false
  },
  methods: {
    loadData(id) {
      if (this.$listeners && this.$listeners.load) {
        this.$emit('load', id)
      }
    },
    submit(type) {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          this.submitType = type

          const data = this.update && this.diffOnly
            ? this.updatedData()
            : this.formData()

          // console.log(data)
          // return

          // if on update mode
          // eslint-disable-next-line no-unreachable
          if (this.update) {
            this.$emit('submit', this.$route.params.id, data, this.submitCallback)
            return
          }

          this.$emit('submit', data, this.submitCallback)
        }
      })
    },
    submitCallback() {
      let successMessage = this.successMessage || 'The data has been submitted'

      if (this.create || this.update) {
        successMessage = this.label
          ? `The ${this.label} data have been saved.`
          : 'The data has been saved'
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          text: successMessage,
          variant: 'success',
        },
      })

      // TODO: need to optimize the reset function, apply form reset rather than emit reset
      if (!this.update && this.submitType === 'stay') {
        this.reset()
      }

      if (this.update && this.submitType === 'stay') {
        this.isSubmitAllowed = false
      }

      if (this.submitType === 'return') {
        this.$router.back()
      }
    },
    getRules(field) {
      const rules = []

      if (field.required) {
        rules.push('required')
      }

      if (field.type === 'email') {
        rules.push('email')
      }

      if (field.minLength) {
        rules.push(`min:${field.minLength}`)
      }

      if (field.maxLength) {
        rules.push(`max:${field.maxLength}`)
      }

      if (field.min) {
        rules.push(`min_value:${field.min}`)
      }

      if (field.max) {
        rules.push(`max_value:${field.max}`)
      }

      return rules.join('|')
    },
    input() {
      const data = this.updatedData()
      this.isSubmitAllowed = !this._.isEmpty(data)
    },
    formData() {
      // convert field's array to object
      return this.defaultFields
        .map(item => ({
          ...item,
          value: item.value && item.type === 'number'
            ? parseFloat(item.value)
            : item.value,
        }))
        .filter(item => {
          const isEmpty = [undefined, null, ''].includes(item.value)
          const isRequired = item.required

          // only get non-empty field or not required(?)
          return (!isEmpty || !isRequired)
            && (!item.visibility || item.visibility.callback(this.defaultFields))
        })
        .reduce((o, { key, value }) => this._.set(o, key, value), {})
    },
    // returns only the values that have been changed
    // computed cannot listen to property's child changes
    updatedData() {
      return this.diff(this.data, this.formData())
    },
    // TODO: need to optimize the diff function
    diff(obj1, obj2) {
      const res = updatedDiff(obj1, obj2)
      // ignore arrays value from diff
      if (obj1) {
        Object.keys(obj1).filter(k => Array.isArray(obj1[k])).forEach(k => {
          res[k] = obj2[k]
        })
      }
      return res
    },
    cancel() {
      if (this.$listeners && this.$listeners.cancel) {
        this.$emit('cancel')
        return
      }

      // default action on cancel
      this.$router.back()
    },
    getValue(item) {
      // set default if not yet being set
      if (typeof item.value === 'undefined' && this.update) {
        return this._.get(this.data, item.key)
      }

      return item.value
    },
    reset() {
      // reset to default fields without 'value'
      this.defaultFields = this._.cloneDeep(this.fields)

      // reset validation state
      if (this.$refs.formValidation) {
        this.$refs.formValidation.reset()
      }
    },
    bindFormData() {
      // Set the form field's default value
      // using the data from API
      this.defaultFields = this.defaultFields.map(item => ({
        ...item,
        value: this.getValue(item),
      }))

      // load initial selected for 'select' or 'multiple'
      // from the actionSearch with ids parameter. bind to
      // .selected and valueOptions
      this.loadFieldSelectedAction()

      this.loadFieldMultiple()
    },
    loadFieldOptions() {
      // fields that has 'select' or 'multiple' type
      const selectFields = this.defaultFields.filter(item => (item.initialOptions
          && (item.type === 'select' || item.type === 'multiple'))
        && item.actionSearch)

      selectFields.forEach(item => {
        this.$store.dispatch(item.actionSearch, {
          perPage: this.searchOptionsLimit,
        })
          .then(data => this.setFieldOptions(item, data))
      })
    },
    // multiple without action
    loadFieldMultiple() {
      // fields that has 'multiple' type but doesn't have action search. Basically a free text or has static options
      const multipleFields = this.defaultFields.filter(item => (item.type === 'multiple'
        && !item.actionSearch))

      multipleFields.forEach(item => {
        if (item.value) {
          this.setFieldMultiple(item, item.value)
        }
      })
    },
    setFieldMultiple(item, value) {
      if (!value || !value.length) {
        return
      }
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(field => field.key === item.key)

      // static options
      if (item.options) {
        const fallbackOptionKey = item.actionSearch ? 'id' : 'value'

        fields[index].valueOptions = value.map(optionValue => item.options
          .find(option => option[item.option_key || fallbackOptionKey] === optionValue))
      } else {
        // free text without options
        fields[index].valueOptions = value.map(text => ({ text }))
      }

      this.defaultFields = fields
    },
    loadFieldSelectedAction() {
      // fields that has 'select' or 'multiple' type
      const selectFields = this.defaultFields.filter(item => (item.type === 'select'
          || item.type === 'multiple')
        && item.actionSearch)

      selectFields.forEach(item => {
        if ((item.type === 'select' && item.value)
          || (item.type !== 'select' && item.value && item.value.length)) {
          const ids = item.type === 'select' ? item.value : item.value.join(',')
          this.$store.dispatch(item.actionSearch, {
            ids,
            perPage: item.type === 'select' ? 1 : item.value.length,
          })
            // the 'data' below is an array
            .then(data => this.setFieldSelected(item, data))
        }
      })
    },

    // Uploading image using text editor
    async handleUpload(blobInfo, success, failure) {
      try {
        if (!blobInfo) return
        const formData = new FormData()
        formData.append('file', blobInfo.blob(), blobInfo.filename())
        formData.append('name', blobInfo.filename)
        formData.append('type', 'image')
        const mediaRes = await this.$http.post('/v1/media', formData)
        const imageUrl = get(mediaRes, 'data.data.url')
        success(imageUrl)
      } catch (error) {
        failure('error')
      }
    },
    onBlurSelect(field) {
      if (field.type !== 'multiple') {
        return
      }

      this.$refs[`select-input-${field.key}`][0].focus()
      this.$refs[`select-input-${field.key}`][0].blur()
    },
    inputSelect(field, option) {
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(item => item.key === field.key)
      const fallbackOptionKey = field.actionSearch ? 'id' : 'value'
      const value = option[field.option_key || fallbackOptionKey]

      // if multiple select, clear the input after selecting
      if (field.type === 'multiple') {
        fields[index].selected = null

        // valueOptions is the array of selected options
        if (!fields[index].valueOptions) {
          fields[index].value = [value]
          fields[index].valueOptions = [option]
        } else if (fields[index].valueOptions
          .findIndex(valueOption => valueOption[field.option_key
            || fallbackOptionKey] === option[field.option_key
            || fallbackOptionKey]) === -1) {
          // push if not selected yet
          fields[index].value.push(value)
          fields[index].valueOptions.push(option)
        }
      } else {
        fields[index].value = value
      }

      this.defaultFields = fields
      // TODO: should use this.input()
      // this.input()
      this.isSubmitAllowed = true
    },
    inputDeselect(field, option) {
      // when clear value but not multiple value
      // because multiple value doesn't use vue-select default selected key
      if (option === null) {
        const fields = this._.cloneDeep(this.defaultFields)
        const index = fields.findIndex(item => item.key === field.key)

        if (fields[index].value && fields[index].type !== 'multiple') {
          fields[index].selected = null
          fields[index].value = null

          this.defaultFields = fields
          // TODO: should use this.input(), it doesn't work
          // this.input()
          this.isSubmitAllowed = true
        }
      }
    },
    searchOptions: debounce(function search(field, keyword, setLoading) {
      // do not reload to initial options if options already exists
      // this behavior may not suitable if you want to always ...
      // load the default list when keyword is empty
      if (!field.initialOptions && field.options && field.options.length && !keyword) {
        return
      }
      setLoading(true)

      this.$store.dispatch(field.actionSearch, {
        keyword,
        perPage: this.searchOptionsLimit,
      })
        .then(data => this.setFieldOptions(field, data))
        .finally(() => {
          setLoading(false)
        })
    }, 1000),
    setFieldOptions(item, data) {
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(field => field.key === item.key)
      fields[index].options = data
      this.defaultFields = fields
    },
    setFieldSelected(item, data) {
      if (!data || !data.length) {
        return
      }

      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(field => field.key === item.key)

      if (item.type === 'multiple') {
        // if it is a multiple select field, set all
        fields[index].valueOptions = data
      } else {
        // if it is a single select field, get the first index
        [fields[index].selected] = data
      }

      this.defaultFields = fields
    },
    inputBasicMultiple(field, e) {
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(item => item.key === field.key)
      const { value } = e.target

      if (!fields[index].value || !Array.isArray(fields[index].value)) {
        fields[index].value = [value]
        fields[index].valueOptions = [{ text: value }]

        this.defaultFields = fields
      } else if (!fields[index].value.includes(value)) {
        fields[index].value.push(value)

        if (Array.isArray(fields[index].valueOptions)) {
          fields[index].valueOptions.push({ text: value })
        } else {
          fields[index].valueOptions = [{ text: value }]
        }

        this.defaultFields = fields
      }

      e.target.value = ''
    },
    removeOption(field, index) {
      field.valueOptions.splice(index, 1)
      field.value.splice(index, 1)

      const fields = this._.cloneDeep(this.defaultFields)
      const fieldIndex = fields.findIndex(item => item.key === field.key)
      fields[fieldIndex] = field

      this.defaultFields = fields
      // TODO: should use this.input()
      // this.input()
      this.isSubmitAllowed = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
