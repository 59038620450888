<template>
  <div>
    <!-- Actual input field -->
    <b-form-file
      ref="file"
      v-model="files"
      accept="image/*"
      multiple
      @input="uploadFile"
    />

    <!-- Image preview -->
    <transition-group
      v-if="defaultValue && defaultValue.length"
      name="flip-list"
      class="form-row align-items-center mt-75"
      tag="div"
      @dragover.prevent
      @dragenter.prevent
    >
      <b-col
        v-for="(item, index) of defaultValue"
        :key="`image-${item.sort}`"
        cols="auto"
        :draggable="true"
        @dragover="dragOver(item)"
        @dragend="dragEnd"
        @dragstart="dragStart($event)"
      >
        <b-overlay
          :show="item.loading"
          rounded="lg"
        >
          <div class="h-100px w-100px position-relative">
            <b-img
              :src="$options.filters.thumbnail(item.url, 100)"
              class="w-100 h-100 img-cover rounded-lg"
            />
            <feather-icon
              v-if="!item.loading"
              icon="XIcon"
              size="22"
              class="remove__btn"
              @click="remove(index)"
            />
          </div>
        </b-overlay>
      </b-col>
    </transition-group>
  </div>
</template>

<script>

import {
  BCol,
  BFormFile,
  BImg,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BFormFile,
    BImg,
    BOverlay,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      files: null,
      over: {},
      allowReorder: true,
      selectedIndex: null,
      selectedImage: null,

      ghostElement: null,
    }
  },
  computed: {
    defaultValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    // remove the ghost effect by set the image to transparent
    dragStart(event) {
      if (!this.ghostElement) {
        this.ghostElement = document.createElement('div')
        this.ghostElement.classList.add('opacity-0')
        this.ghostElement.innerHTML = 'ghost'

        // Append it to `body`
        document.body.appendChild(this.ghostElement)
      }

      // Customize the drag image
      event.dataTransfer.setDragImage(this.ghostElement, 0, 0)
    },
    // create dragEnd event for drag and drop and set the list
    dragEnd() {
      this.selectedImage = null
      this.selectedIndex = null
    },
    // create onDragOver event for drag and drop
    dragOver(image) {
      if (!this.allowReorder || image.loading) {
        return
      }

      const hoverIndex = this.defaultValue.indexOf(image)

      if (!this.selectedImage) {
        this.selectedImage = image
        this.selectedIndex = hoverIndex

        return
      }

      if (this.selectedIndex === hoverIndex) {
        return
      }

      this.defaultValue.splice(this.selectedIndex, 1)
      this.defaultValue.splice(hoverIndex, 0, this.selectedImage)

      // to handle UI issue, where while transitioning,
      // the image order is keep changing
      this.allowReorder = false
      setTimeout(() => {
        this.allowReorder = true
      }, 200) // based on css transition duration

      // set as current index
      this.selectedIndex = hoverIndex
      this.$emit('input', this.defaultValue)
    },
    // trigger click the actual input file field
    selectFile() {
      this.$refs.file.$el.childNodes[0].click()
    },
    // set Image in the next tick after the image selected,
    // so this.value will be refreshed
    uploadFile() {
      const currentLength = this.defaultValue.length

      this.defaultValue = [...this.defaultValue, ...this.files.map((e, i) => ({
        url: URL.createObjectURL(e),
        sort: currentLength + i,
        loading: true,
      }))]

      // create promise all to upload all images from this.files
      // and set the image url to the defaultValue
      const promises = this.files.map(file => this.$store.dispatch('image/upload', file))
      Promise.all(promises)
        .then(res => {
          const urls = res.map(r => r.data.data.url).map((url, i) => ({ url, sort: currentLength + i }))
          this.defaultValue = [...this.defaultValue.filter(e => !e.url.includes('blob')), ...urls]
        })
        .catch(() => {
          this.defaultValue = this.defaultValue.filter(({ loading }) => !loading)
        })
    },
    remove(i) {
      // remove files from the list
      if (this.files) {
        this.files.splice(i, 1)
      }
      if (this.defaultValue) {
        this.defaultValue.splice(i, 1)
      }

      this.$emit('input', this.defaultValue)
    },
  },
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.2s;
}

div[draggable="true"] {
  cursor: grab;
}

div[draggable="true"]:active,
div[draggable="true"]:focus-within,
div[draggable="true"]:focus {
  opacity: 0.5;
  cursor: grabbing;
  z-index: 2;
}

.remove__btn {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  cursor: pointer;
  z-index: 1;
  background: grey;
  padding: 4px;
  color: white;
  border-radius: 100%;
}
</style>
