<template>
  <div>
    <b-form-row class="align-items-center">
      <b-col cols="auto">
        <!-- Image preview -->
        <div class="w-100px h-100px border-2 border-dashed border-default rounded-lg">
          <a
            v-if="imageUrl"
            :href="imageUrl"
            target="_blank"
            class="w-100 h-100 d-block p-25"
          >
            <b-overlay
              class="h-100 w-100"
              :show="loading"
            >
              <b-img
                :src="imageUrl"
                class="w-100 h-100 img-cover rounded"
              />
            </b-overlay>
          </a>
          <div
            v-else
            class="d-flex align-items-center justify-content-center cursor-pointer w-100 h-100"
            @click="selectFile"
          >
            <div class="text-center">
              <feather-icon
                icon="UploadIcon"
                size="16"
                class="icon"
              />
              <div class="s-14 mt-25">
                Upload
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Reset file and preview image -->
      <b-col
        v-if="imageUrl"
        cols="auto"
      >
        <b-button
          variant="secondary"
          @click="removeFile"
        >
          Remove
        </b-button>
      </b-col>
      <!-- Select or change image button -->
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="selectFile"
        >
          <span v-if="imageUrl">Change image</span>
          <span v-else>Select image</span>
        </b-button>
      </b-col>
    </b-form-row>
    <!-- Actual input field -->
    <b-form-file
      ref="file"
      v-model="file"
      class="d-none"
      @input="uploadFile"
    />
    <!-- Image url input field -->
    <!-- This is the value that will be submitted to the API -->
    <input
      v-model="defaultValue"
      hidden
    >
  </div>
</template>

<script>
import {
  BFormRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BFormRow,
    BCol,
    BButton,
    BFormFile,
    BImg,
    BOverlay,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      file: null,
      imageUrl: null,
      loading: false,
    }
  },
  computed: {
    defaultValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    value() {
      this.setImageUrl()
    },
  },
  methods: {
    // trigger click the actual input file field
    selectFile() {
      this.$refs.file.$el.childNodes[0].click()
    },
    // set Image in the next tick after the image selected,
    // so this.value will be refreshed
    uploadFile() {
      // file field @input triggered when value 'file' state changed
      // prevent with this validation
      if (!this.file) {
        return
      }

      // set initial image preview
      this.$nextTick(this.setImageUrl)

      this.loading = true
      this.$store.dispatch('image/upload', this.file)
        .then(res => {
          const { url } = res.data.data
          this.defaultValue = url
        })
        .finally(() => {
          this.loading = false
        })
    },
    removeFile() {
      this.file = null
      // this.defaultValue = null
      // set image url to empty string (API should accept null though)
      // so it will get submitted to the API
      // and will remove it from the database
      this.defaultValue = ''
    },
    // Not using computed because it won't update after
    // selecting image when the initial value is null
    setImageUrl() {
      if (!this.file && !this.defaultValue) {
        this.imageUrl = null
        return
      }

      if (this.file) {
        this.imageUrl = URL.createObjectURL(this.file)
        return
      }

      this.imageUrl = this.defaultValue
    },
  },
}
</script>

<style scoped>

</style>
