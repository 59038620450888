<template>
  <div class="w-100 mt-25">
    <FormView
      :data="data"
      :fields="fields"
      lazy
    />
  </div>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

export default {
  components: {
    FormView,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fields() {
      const fields = [
        {
          key: 'barThickness',
          label: 'Bar thickness',
          suffix: 'px',
        },
        {
          key: 'layout',
          label: 'Layout',
          class: 'text-capitalize',
          type: 'dot',
          map: {},
        },
        {
          key: 'font',
          label: 'Font',
          type: 'dot',
          map: {},
        },
        {
          key: 'textColor',
          type: 'color',
          label: 'Text color',
        },
        {
          key: 'barTextColor',
          type: 'color',
          label: 'Bar text color',
        },
        {
          key: 'barColor',
          type: 'color',
          label: 'Bar color',
        },
        {
          key: 'barBackgroundColor',
          type: 'color',
          label: 'Bar background color',
        },
      ]

      return fields.filter(item => item.visible !== false)
    },
  },
}
</script>

<style scoped>

</style>
