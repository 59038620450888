<template>
  <div class="w-100 mt-25">
    <FormView
      :data="defaultData"
      :fields="fields"
      lazy
    />
  </div>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

export default {
  components: {
    FormView,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    defaultData() {
      return {
        ...this.data,
        is_active: this.data.textToSpeechEnabled ? 'active' : 'inactive',
      }
    },
    fields() {
      const fields = [
        {
          key: 'is_active',
          label: 'Status',
          type: 'badge',
          map: {
            active: 'success',
            inactive: 'secondary',
          },
        },
        {
          key: 'textToSpeech.includeTemplate',
          label: 'Include template',
          type: 'boolean',
        },
        {
          key: 'textToSpeech.minDonationToRead',
          label: 'Min. amount to read',
          prefix: '$',
        },
        {
          key: 'textToSpeech.voice',
          label: 'Voice',
        },
        {
          key: 'textToSpeech.volume',
          label: 'Volume',
        },
      ]

      return fields.filter(item => item.visible !== false)
    },
  },
}
</script>

<style scoped>

</style>
