<template>
  <div class="w-100 mt-25">
    <FormView
      :data="data"
      :fields="fields"
      lazy
    />
  </div>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

export default {
  components: {
    FormView,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fields() {
      const fields = [
        {
          key: 'condition',
          label: 'Condition',
          visible: this.data.condition !== 'MAIN',
          type: 'badge',
          map: {},
        },
        {
          key: 'textLayout',
          label: 'Layout',
          class: 'text-capitalize',
          type: 'dot',
          map: {},
        },
        {
          key: 'donationAlertImages.url',
          label: 'Custom image',
          type: 'image',
        },
        {
          key: 'donationAlertSounds.url',
          label: 'Custom audio',
          type: 'audio',
        },
        {
          key: 'alertAnimation.in',
          label: 'Animation in',
          class: 'text-capitalize',
          type: 'dot',
          map: {},
        },
        {
          key: 'alertAnimation.out',
          label: 'Animation out',
          class: 'text-capitalize',
          type: 'dot',
          map: {},
        },
        {
          key: 'minDonationAmount',
          label: 'Min. amount to alert',
          prefix: '$',
        },
        {
          key: 'messageTemplate',
          label: 'Template',
        },
        {
          key: 'textAnimation',
          label: 'Text animation',
          class: 'text-capitalize',
          type: 'dot',
          map: {},
        },
        {
          key: 'soundVolume',
          label: 'Sound volume',
        },
        {
          key: 'alertDuration',
          label: 'Alert duration',
          suffix: ' second(s)',
        },
        {
          key: 'alertTextDelay',
          label: 'Alert text delay',
          suffix: ' second(s)',
        },
        {
          key: 'fontSetting.fontType',
          label: 'Font',
        },
        {
          key: 'fontSetting.fontSize',
          label: 'Font size',
        },
        {
          key: 'fontSetting.textBaseColor',
          type: 'color',
          label: 'Text base color',
        },
        {
          key: 'fontSetting.textHighlightColor',
          type: 'color',
          label: 'Text highlight color',
        },
      ]

      return fields.filter(item => item.visible !== false)
    },
  },
}
</script>

  <style scoped>

  </style>
