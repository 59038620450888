<template>
  <div>
    <b-row>
      <!-- Avatar -->
      <b-col cols="auto">
        <b-avatar
          size="48px"
          rounded
          :variant="titleMap[transactionTitleKey].variant"
        >
          <feather-icon
            :icon="titleMap[transactionTitleKey].icon"
            size="20"
          />
        </b-avatar>
      </b-col>

      <!-- Content -->
      <b-col>
        <b-row>
          <!-- Title -->
          <b-col>
            <b-row
              no-gutters
              class="mb-50"
              align-v="center"
            >
              <b-col cols="auto">
                <h4
                  v-if="defaultTitle"
                  class="mb-0"
                >
                  {{ defaultTitle }}
                </h4>
                <h4
                  v-else
                  class="text-muted mb-0"
                >
                  <i>No title</i>
                </h4>
              </b-col>
              <!-- Description tooltip -->
              <b-col
                v-if="description"
                cols="auto"
              >
                <feather-icon
                  v-b-tooltip.v-dark.top="description"
                  size="20"
                  icon="InfoIcon"
                  class="text-muted ml-50"
                />
              </b-col>
            </b-row>

            <div v-if="supporterUsername && supporterId">
              Supporter: <a :href="`/user/${supporterId}`">{{ supporterUsername }}</a>
            </div>
          </b-col>
          <!-- Token -->
          <b-col
            v-b-tooltip.v-dark="currency && price ? `${currency.toUpperCase()} ${$options.filters.number(price)}` : undefined"
            cols="auto"
            class="mb-0"
          >
            <!-- Gold -->
            <div v-if="walletType === 'credit'">
              <h4 class="mb-0 d-flex align-items-center">
                <span
                  v-if="type === 'credit'"
                  class="mr-25"
                >+</span>
                <TableField
                  :value="token"
                  type="gold"
                  hide-copy
                />
              </h4>
            </div>
            <!-- Dollar  -->
            <div v-else-if="walletType === 'earning'">
              <h4 class="mb-0">
                <span
                  v-if="type === 'credit'"
                  class="mr-25"
                >+</span>${{ token | number }}
              </h4>
            </div>
            <!-- Unknown, assume it is dollar also  -->
            <div v-else-if="!walletType">
              <h4 class="mb-0">
                <span
                  v-if="type === 'credit'"
                  class="mr-25"
                >+</span>${{ token | number }}
              </h4>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-50">
          <!-- Created At -->
          <b-col v-if="createdAt">
            {{ createdAt | datetime }}
          </b-col>
          <!-- Token -->
          <b-col
            cols="auto"
          >
            <TableField
              :value="status"
              :map="statusMap"
              type="dot"
              hide-copy
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import TableField from '@/layouts/components/TableField.vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    TableField,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: undefined,
    },
    walletType: {
      type: String,
      default: undefined,
    },
    status: {
      type: String,
      required: true,
    },
    token: {
      type: Number,
      required: true,
    },
    supporterUsername: {
      type: String,
      default: '',
    },
    supporterId: {
      type: String,
      default: '',
    },
    createdAt: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      default: undefined,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statusMap: {
        pending: 'warning',
        rejected: 'danger',
        failed: 'danger',
        succeeded: 'success',
        refunded: 'info',
      },
      titleMap: {
        withdrawal: { icon: 'DollarSignIcon', variant: 'light-info' },
        donation: { icon: 'GiftIcon', variant: 'light-success' },
        topup: { icon: 'ArrowUpCircleIcon', variant: 'light-warning' },
        membership: { icon: 'AwardIcon', variant: 'light-primary' },
        upgrade_plan: { icon: 'AwardIcon', variant: 'light-primary' },
        voucher: { icon: 'HomeIcon', variant: 'light-secondary' },
        ppv: { icon: 'PlayCircleIcon', variant: 'light-primary' },
        order: { icon: 'ShoppingCartIcon', variant: 'light-warning' },
        digital: { icon: 'ImageIcon', variant: 'light-info' },
        other: { icon: 'ListIcon', variant: 'light-secondary' },
      },
    }
  },
  computed: {
    defaultTitle() {
      if (!this.title) {
        return this.title
      }

      let split = this.title.split('-')

      let title = split.length > 1
        ? split[0]
        : this.title

      split = title.split(' G00')

      title = split.length > 1
        ? split[0]
        : title

      return title.trim()
    },
    transactionTitleKey() {
      const title = this.title.toLowerCase()

      if (title.includes('withdrawal')) {
        return 'withdrawal'
      } if (title.includes('donation')) {
        return 'donation'
      } if (title.includes('membership')) {
        return 'membership'
      } if (title.includes('topup')) {
        return 'topup'
      } if (title.includes('upgrade plan')) {
        return 'upgrade_plan'
      } if (title.includes('voucher')) {
        return 'voucher'
      } if (title.includes('ppv')) {
        return 'ppv'
      } if (title.includes('order')) {
        return 'order'
      } if (title.includes('digital')) {
        return 'digital'
      }

      return 'other'
    },
  },
}
</script>

<style scoped>

</style>
